const Text = {
  variants: {
    bold: {
      fontSize: 'xl',
      lineHeight: '7',
      fontWeight: 'bold',
      pr: '1',
      mb: '6',
    },
    signature: {
      fontSize: 'md',
      lineHeight: '6',
      fontWeight: 'bold',
      pr: '1',
      mb: '1',
      textColor: 'brand.500',
    },
    secondary: {
      fontSize: 'sm',
      lineHeight: '5',
      fontWeight: 'normal',
      pr: '1',
      pb: '5',
      textColor: 'grey.600',
    },
  },
  defaultProps: {
    variant: 'full',
  },
}

export default Text
