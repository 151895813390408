const Heading = {
  baseStyle: {
    textAlign: 'center',
    px: 3,
  },
  defaultProps: {
    size: { base: 'xxs', md: 'xs' },
    as: 'h2',
  },
}

export default Heading
