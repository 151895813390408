import { theme } from '@chakra-ui/pro-theme'

const buttonStyles = theme.components.Button
const Link = {
  // style object for base or default style
  // baseStyle: {},
  // styles for different sizes ("sm", "md", "lg")
  // sizes: {},
  // styles for different visual variants ("outline", "solid")
  variants: {
    default: {
      textDecoration: 'underline',
      color: 'blue.600',
      _hover: {
        cursor: 'pointer',
      },
    },
    button: {
      ...buttonStyles.baseStyle,
      bg: 'brand.500',
      color: 'white',
      _hover: {
        bg: 'brand.600',
        boxShadow: 'sm',
        textDecoration: 'none',
      },
      _active: {
        bg: 'brand.600',
      },
    },
  },
  // default values for `size` and `variant`
  // defaultProps: {
  //   size: '',
  //   variant: '',
  // },
}

export default Link
