import { createContext } from 'react'

const defaultMobileDimensions = {
  mobileHeight: '100vh',
  mobileWidth: '100vh',
  isMobileScreenSize: false,
}

const MobileDimensionsContext = createContext(defaultMobileDimensions)
// Shows up in React dev tools for easier debugging
MobileDimensionsContext.displayName = 'Mobile Dimensions Context'

export { MobileDimensionsContext, defaultMobileDimensions }
