import { extendTheme } from '@chakra-ui/react'
import { theme as proTheme } from '@chakra-ui/pro-theme'
import '@fontsource/inter/variable.css'

// Global style overrides
import styles from './styles'

// Foundational style overrides
import colors from './foundations/colors'
import borders from './foundations/borders'

// Component style overrides
import Button from './components/button'
import Link from './components/link'
import Banner from './components/banner'
import CommodityTag from './components/commodityTag'
import CommodityIcon from './components/commodityIcon'
import Text from './components/text'
import Heading from './components/heading'

const overrides = {
  styles,
  colors,
  borders,
  // Other foundational style overrides go here
  // Examples incudes blur, colors, shadows, sizes, spacing, typography, and transitions, etc...
  components: {
    Button,
    Link,
    Banner,
    CommodityTag,
    CommodityIcon,
    Text,
    Heading,
    // Other components go here
  },
}

export default extendTheme(proTheme, overrides)
