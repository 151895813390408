// NOTE: THESE COLORS ARE FROM FIGMA OR FH WEBSITE AND ARE LIKELY TO CHANGE WHEN WE GET NEW COLOR SCHEME INFO FROM GERONIMO
const colors = {
  brand: {
    50: '#71d9a5',
    100: '#49ce8c',
    200: '#35c97f',
    300: '#30b472',
    400: '#2ba065',
    500: '#20784C',
    600: '#155033',
    700: '#103c26',
    800: '#0b2719',
    blue: {
      hover: '#26728a',
      primary: '#2B839E',
    },
    gray: {
      97: '#F7F8F8',
      background: '#E5E5E5',
      disable: '#C4C4C4',
      header: '#DEDEDE',
      text: '#2D3748',
      outline: '#E2E8F0',
    },
    green: {
      banner: '#2C9B64',
    },
  },
}

export default colors
