const Banner = {
  baseStyle: {
    fontWeight: 'medium',
  },
  variants: {
    header: {
      backgroundColor: 'brand.gray.header',
      fontWeight: 'bold',
      fontSize: '3xl',
      color: 'black',
    },
    primary: {
      backgroundColor: 'brand.700',
      color: 'white',
    },
    secondary: {
      backgroundColor: 'brand.green.banner',
      color: 'white',
    },
    alert: {
      backgroundColor: '#eb3434',
      color: 'white',
    },
  },
}

export default Banner
