// This is an example -> It is the default borders foundation file from the default Chakra theme
// https://github.com/chakra-ui/chakra-ui/tree/main/packages/theme/src/foundations

const borders = {
  // none: 0,
  // "1px": "1px solid",
  // "2px": "2px solid",
  // "4px": "4px solid",
  // "8px": "8px solid",
}

export default borders
