import { useEffect, useState, useRef } from 'react'
import { Box, useMediaQuery } from '@chakra-ui/react'
import {
  MobileDimensionsContext,
  defaultMobileDimensions,
} from '@/contexts/MobileDimensionsContext'
import CustomHead from '@/components/layout/CustomHead'

export default function Layout({ children }) {
  const [mobileDimensions, setMobileDimensions] = useState({
    ...defaultMobileDimensions,
    layoutRef: useRef(null),
    orientation: 'portrait',
  })
  const layoutRef = useRef()

  // Determine if on mobile size screen by using the default chakra theme breakpoint 'md'
  const [isLargerThanMd] = useMediaQuery('(min-width: 48em)')

  useEffect(() => {
    const mobileDimensionsHandler = () => {
      if (window !== undefined) {
        const width = window.innerWidth
        const height = window.innerHeight

        const orientation = width > height ? 'landscape' : 'portrait'

        setMobileDimensions({
          isMobileScreenSize: !isLargerThanMd,
          mobileWidth: `${width}px`,
          mobileHeight: `${height}px`,
          orientation,
          /* Provide a ref to this Layout component for a React Portal. The Chakra Drawer component uses a React Portal under the hood. By default, the React Portal is appended to document.body.  Our current use case is providing a mobile commodity search experience in the sign up flow. In order to make styling consistent for this mobile search experience, we can keep this drawer within the main document flow by appending it to this Layout component instead of document.body.
           */
          layoutRef,
        })
      }
    }

    mobileDimensionsHandler()
    if (!isLargerThanMd) {
      window.addEventListener('resize', mobileDimensionsHandler)
    } else {
      window.removeEventListener('resize', mobileDimensionsHandler)
    }
    return () => {
      window.removeEventListener('resize', mobileDimensionsHandler)
    }
  }, [isLargerThanMd])

  return (
    <Box
      h={{ base: mobileDimensions.mobileHeight, md: '100vh' }}
      w={{ base: mobileDimensions.mobileWidth, md: '100vw' }}
      overflowY="auto"
      bg="brand.gray.97"
      ref={layoutRef}
    >
      <CustomHead />
      <MobileDimensionsContext.Provider value={mobileDimensions}>
        {children}
      </MobileDimensionsContext.Provider>
    </Box>
  )
}
