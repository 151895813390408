import Head from 'next/head'

export default function CustomHead() {
  return (
    <Head>
      <title>
        Full Harvest - reduce food waste, buy and sell produce, combat climate
        change
      </title>
      <meta
        name="description"
        content="Our vision is a world where there is 0% food waste and 100% 'full harvests', where all edible produce grown goes towards consumption. We aim to reinvent the produce supply chain with technology to bring the industry online and maximize resources."
      />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1"
      />
    </Head>
  )
}
