const Button = {
  // style object for base or default style
  // baseStyle: {},
  // styles for different sizes ("sm", "md", "lg")
  // sizes: {},
  // styles for different visual variants ("outline", "solid")
  variants: {
    basic: {
      bg: 'white',
      border: '1px',
      borderColor: 'brand.gray.outline',
      _hover: {
        bg: 'brand.gray.97',
        boxShadow: 'sm',
      },
    },
    disable: {
      bg: 'brand.gray.disable',
      color: 'white',
    },
    pill: {
      bg: 'white',
      p: '3 4',
      borderRadius: '20px',
      h: '12',
      textColor: 'brand.gray.text',
      border: '1px',
      borderColor: 'brand.500',
      transitionTimingFunction: 'ease-out',
      transitionDuration: '500ms',
      _hover: {
        boxShadow: 'lg',
      },
      _active: {
        bg: 'brand.500',
        textColor: 'white',
        transform: 'background-color brand.500',
        transitionTimingFunction: 'ease-out',
        transitionDuration: '700ms',
        _hover: {
          boxShadow: 'lg',
        },
      },
    },
    standard: {
      bg: 'brand.blue.primary',
      color: 'white',
      _hover: {
        bg: 'brand.blue.hover',
        boxShadow: 'sm',
      },
      _active: {
        bg: 'brand.blue.hover',
      },
    },
    primary: {
      bg: 'brand.500',
      color: 'white',
      _hover: {
        bg: 'brand.600',
        boxShadow: 'sm',
      },
      _active: {
        bg: 'brand.600',
      },
    },
    ghost: {
      bg: 'transparent',
      color: 'brand.500',
      _hover: {
        bg: 'transparent',
        textDecoration: 'underline',
      },
    },
    row: {
      bg: 'transparent',
      width: '100%',
      _hover: {
        boxShadow: 'lg',
        textColor: 'brand.400',
      },
    },
    secondaryMobile: {
      bg: 'white',
      border: '1px',
      borderColor: 'brand.gray.outline',
      minH: '10',
      h: 'auto',
      py: 4,
      whiteSpace: 'normal',
      fontWeight: 'semibold',
      _active: {
        bg: 'brand.500',
        textColor: 'white',
        transform: 'background-color brand.500',
        transitionTimingFunction: 'ease-out',
        transitionDuration: '700ms',
        _hover: {
          boxShadow: 'lg',
        },
      },
    },
    secondary: {
      w: 'full',
      fontWeight: 'semibold',
      whiteSpace: 'wrap',
      _active: {
        bg: 'brand.500',
        textColor: 'white',
        transform: 'background-color brand.500',
        transitionTimingFunction: 'ease-out',
        transitionDuration: '700ms',
        _hover: {
          boxShadow: 'lg',
        },
      },
    },
  },
  // default values for `size` and `variant`
  // defaultProps: {
  // },
}

export default Button
