// log the page view with their URL
const pageView = (url) => {
  window.dataLayer.push({
    event: 'pageView',
    page: url,
  })
}

// TODO: fully outline all events to track using a standardized structure
// List of User events to track
const userEvents = {
  buttonClicked: {
    buttonType: '',
    buttonAction: '',
  },
  searchSubmitted: {
    searchQuery: '',
    userType: '',
  },
}

// log specific user events
const event = (eventName, eventData) => {
  let eventDataToSend = userEvents.eventName
  eventDataToSend = { ...eventDataToSend, ...eventData }

  window.dataLayer.push({ event: eventName, ...eventDataToSend })
}

const GoogleTagManager = {
  sendPageChange: pageView,
  sendUserEvent: event,
}
export default GoogleTagManager
