const CommodityTag = {
  // style object for base or default style
  // baseStyle: {},
  // styles for different sizes ("sm", "md", "lg")
  // sizes: {},
  // styles for different visual variants ("outline", "solid")
  variants: {
    imageName: {
      fontSize: { base: '24px', md: '2rem' }, // no equivalent to 2rem in chakra props
      fontWeight: 'bold',
      gap: '2',
      h: '12',
    },
    pill: {
      fontSize: { base: '14px', md: 'lg' },
      gap: { base: '1', md: '2' },
      h: '6',
    },
    withoutBorderBase: {
      fontSize: 'xl',
      fontWeight: 'bold',
      gap: '2',
      h: '12',
    },
    withoutBorderMd: {
      fontSize: '3xl',
      fontWeight: 'bold',
      gap: '2',
      h: '12',
    },
  },
  // default values for `size` and `variant`
  // defaultProps: {
  //   size: '',
  //   variant: '',
  // },
}

export default CommodityTag
