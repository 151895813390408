const Image = {
  // style object for base or default style
  baseStyle: {
    boxSize: '2rem',
  },
  // styles for different sizes ("sm", "md", "lg")
  // sizes: {},
  // styles for different visual variants ("outline", "solid")
  variants: {
    imageName: {
      boxSize: '2rem',
    },
    pill: {
      boxSize: { base: '20px', md: '1.5rem' },
    },
  },
  // default values for `size` and `variant`
  // defaultProps: {
  //   size: '',
  //   variant: '',
  // },
}

export default Image
